
        <template>
          <div class="vc-snippet-doc">
            <h2>Editor 富文本</h2>
<p>封装自wangEditor v4。</p>
<p>支持一个页面创建多个编辑器。</p>
<p><a href="http://www.wangeditor.com/doc/">wangEditor文档</a></p>
<h3>基础用法</h3>
<p>基础的用法。</p>
<vc-snippet>
                  <div slot="desc"></div>
                  <vc-snippent-0 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-editor</span> <span class="hljs-attr">:editor-data.sync</span>=<span class="hljs-string">"text"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ixu-editor</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
  <span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
      <span class="hljs-keyword">return</span> {
        <span class="hljs-attr">text</span>: <span class="hljs-string">'&lt;p&gt;封装自wangEditor v4。支持一个页面创建多个编辑器。&lt;/p&gt;'</span>,
      }
    }
  }
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>自定义行高</h3>
<p>自定义行高的用法。</p>
<vc-snippet>
                  <div slot="desc"><p>通过设置<code>line-heights</code>属性来配置它们。</p>
</div>
                  <vc-snippent-1 slot="source" />
                  <div slot="code"><pre class="hljs"><code><span class="hljs-tag">&lt;<span class="hljs-name">template</span>&gt;</span>
  <span class="hljs-tag">&lt;<span class="hljs-name">ixu-editor</span> <span class="hljs-attr">:editor-data.sync</span>=<span class="hljs-string">"text2"</span> <span class="hljs-attr">:line-heights</span>=<span class="hljs-string">"[0.5]"</span>&gt;</span><span class="hljs-tag">&lt;/<span class="hljs-name">ixu-editor</span>&gt;</span>
<span class="hljs-tag">&lt;/<span class="hljs-name">template</span>&gt;</span>

<span class="hljs-tag">&lt;<span class="hljs-name">script</span>&gt;</span><span class="javascript">
  <span class="hljs-keyword">export</span> <span class="hljs-keyword">default</span> {
    data() {
      <span class="hljs-keyword">return</span> {
        <span class="hljs-attr">text2</span>: <span class="hljs-string">'&lt;p&gt;10086&lt;/p&gt;'</span>
      }
    }
  }
</span><span class="hljs-tag">&lt;/<span class="hljs-name">script</span>&gt;</span>
</code></pre>
    </div>
                </vc-snippet> <h3>Attributes</h3>
<table>
<thead>
<tr>
<th>参数</th>
<th>说明</th>
<th>类型</th>
<th>可选值</th>
<th>默认值</th>
</tr>
</thead>
<tbody>
<tr>
<td>editor-data</td>
<td>文本，支持 .sync 修饰符</td>
<td>string</td>
<td>空</td>
<td></td>
</tr>
<tr>
<td>colors</td>
<td>配置颜色（文字颜色、背景色）</td>
<td>array</td>
<td>-</td>
<td></td>
</tr>
<tr>
<td>lineHeights</td>
<td>配置行高</td>
<td>array</td>
<td>-</td>
<td></td>
</tr>
<tr>
<td>menus</td>
<td>配置菜单栏，删减菜单，调整顺序</td>
<td>array</td>
<td>-</td>
<td>—</td>
</tr>
</tbody>
</table>

          </div>
        </template>
        <script>
           //
//
//
// //
//
//
//
           export default {
           name: 'vc-component-doc',
           components: {
            "vc-snippent-0":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ixu-editor',{attrs:{"editor-data":_vm.text},on:{"update:editorData":function($event){_vm.text=$event},"update:editor-data":function($event){_vm.text=$event}}})}
var staticRenderFns = []

          const exportJavaScript =  {
  data() {
    return {
      text: '<p>封装自wangEditor v4。支持一个页面创建多个编辑器。</p>',
    }
  }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })(),"vc-snippent-1":(function () {
          var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ixu-editor',{attrs:{"editor-data":_vm.text2,"line-heights":[0.5]},on:{"update:editorData":function($event){_vm.text2=$event},"update:editor-data":function($event){_vm.text2=$event}}})}
var staticRenderFns = []

          const exportJavaScript =  {
  data() {
    return {
      text2: '<p>10086</p>'
    }
  }
}

           return {
             ...exportJavaScript,
             render,
             staticRenderFns
          }
        })()
           }
         }
       </script>
       <style lang='scss'>
         
       </style>